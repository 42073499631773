const emailRegex = RegExp(
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
);

export const nameValidator = (val: string | number) => {
  const trimmed = typeof val === "string" ? val.trim() : String(val);

  return { val: trimmed, allow: true, error: trimmed.length ? undefined : "Please enter a valid name." };
};

export const phoneNumberValidator = (val: string | number) => {
  const trimmed = typeof val === "string" ? val.trim() : String(val);
  const numbersOnly = trimmed.replace(/-/g, "");

  const success = trimmed.match(/(\d{3})-(\d{3})-(\d{4})/)?.[0] === trimmed || numbersOnly.length === 10;
  const allow = !Number.isNaN(Number(numbersOnly)) && trimmed.length <= 12;
  const error = allow ? "Please enter a valid number in the format shown." : undefined;

  return { val: trimmed, allow, error: success ? undefined : error };
};

export const emailValidator = (val: string | number) => {
  const formatted = typeof val === "string" ? val.trim().toLowerCase() : String(val);
  const success = formatted.match(emailRegex)?.[0] === formatted;

  return { val: formatted, allow: true, error: success ? undefined : "Please enter a valid email address." };
};

export const emailOptionalValidator = (val: string | number) => {
  if (val === "") return { val: "", allow: true, error: undefined };

  return emailValidator(val);
};

/** Takes a Date object and returns ie. "2022-12-28" */
export const getDateString = (d: Date = new Date()) =>
  new Date(d).toLocaleDateString("en-GB").split("/").reverse().join("-");

/** 3:04 PM for US Locale */
export const getDisplayTime = (timestamp: string | number, locale = "default") => {
  const date = Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "2-digit",
  }).format(new Date(timestamp));

  return date;
};
