import { useRef, useCallback, useEffect, RefObject } from "react";
import cx from "classnames";

// Context
import { useAppContext } from "context";

// Local
import { removeEventListeners, addEventListeners, getCoords } from "./utils";
import styles from "./styles.module.css";

type TProps = {
  canvasRef: RefObject<HTMLCanvasElement>;
  className?: string;
};

export const CaptureSignature = ({ canvasRef, className }: TProps) => {
  const { setError } = useAppContext();

  const signatureRef = useRef({
    pixels: [] as number[],
    cpixels: [] as number[],
    xyLast: { x: 0, y: 0 },
    xyAddLast: { x: 0, y: 0 },
    calculate: false,
  });

  const onMouseMove = useCallback<EventListener>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const canvas = canvasRef.current;
      const context = canvasRef.current?.getContext("2d");

      if (canvas && context) {
        const { xyLast, pixels, xyAddLast, calculate, ...rest } = signatureRef.current;

        const { x, y } = getCoords(canvas, e);
        const xyAdd = {
          x: (xyLast.x + x) / 2,
          y: (xyLast.y + y) / 2,
        };

        const newPixels = [...pixels];
        let newCalculate = calculate;

        if (calculate) {
          const xLast = (xyAddLast.x + xyLast.x + xyAdd.x) / 3;
          const yLast = (xyAddLast.y + xyLast.y + xyAdd.y) / 3;
          newPixels.push(xLast, yLast);
        } else {
          newCalculate = true;
        }

        context.quadraticCurveTo(xyLast.x, xyLast.y, xyAdd.x, xyAdd.y);

        context.stroke();
        context.beginPath();
        context.moveTo(xyAdd.x, xyAdd.y);

        newPixels.push(xyAdd.x, xyAdd.y);

        signatureRef.current = {
          ...rest,
          calculate: newCalculate,
          pixels: newPixels,
          xyAddLast: xyAdd,
          xyLast: { x, y },
        };
      }
    },
    [canvasRef]
  );

  const onMouseUp = useCallback<EventListener>(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      removeEventListeners(canvas, [
        { event: "mousemove", cb: onMouseMove, type: "canvas" },
        { event: "mouseup", cb: onMouseUp, type: "canvas" },
        { event: "touchmove", cb: onMouseMove, type: "canvas" },
        { event: "touchend", cb: onMouseUp, type: "canvas" },
        { event: "mouseup", cb: onMouseUp, type: "body" },
        { event: "touchend", cb: onMouseUp, type: "body" },
      ]);

      const context = canvasRef.current?.getContext("2d");

      context?.stroke();
      signatureRef.current.calculate = false;
    }
  }, [onMouseMove, canvasRef]);

  const onMouseDown = useCallback<EventListener>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const canvas = canvasRef.current;
      const context = canvasRef.current?.getContext("2d");

      if (canvas && context) {
        setError("");

        addEventListeners(canvasRef.current, [
          { event: "mousemove", cb: onMouseMove, type: "canvas" },
          { event: "mouseup", cb: onMouseUp, type: "canvas" },
          { event: "touchmove", cb: onMouseMove, type: "canvas" },
          { event: "touchend", cb: onMouseUp, type: "canvas" },
          { event: "mouseup", cb: onMouseUp, type: "body" },
          { event: "touchend", cb: onMouseUp, type: "body" },
        ]);

        const { x, y } = getCoords(canvas, e);
        context.beginPath();
        context.moveTo(x, y);

        const { xyLast, pixels, ...rest } = signatureRef.current;

        signatureRef.current = {
          ...rest,
          pixels: [x, y],
          xyLast: { x, y },
        };
      }
    },
    [onMouseMove, onMouseUp, canvasRef, setError]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvasRef.current?.getContext("2d");

    if (canvas && context) {
      context.fillStyle = "#fff";
      context.strokeStyle = "#000";
      context.lineWidth = 1.5;
      context.lineCap = "round";
      context.fillRect(0, 0, canvas.width, canvas.height);

      canvas.addEventListener("touchstart", onMouseDown, false);
      canvas.addEventListener("mousedown", onMouseDown, false);

      return () => {
        removeEventListeners(canvas, [
          { event: "mousemove", cb: onMouseMove, type: "canvas" },
          { event: "mouseup", cb: onMouseUp, type: "canvas" },
          { event: "touchmove", cb: onMouseMove, type: "canvas" },
          { event: "touchend", cb: onMouseUp, type: "canvas" },
          { event: "mouseup", cb: onMouseUp, type: "body" },
          { event: "touchend", cb: onMouseUp, type: "body" },
        ]);
      };
    }
  }, [onMouseDown, onMouseUp, onMouseMove, canvasRef]);

  useEffect(() => {
    setError("Signature not signed.");

    const handleResize = () => {
      const context = canvasRef.current?.getContext("2d");

      const dpr = window.devicePixelRatio || 1;

      const height = canvasRef.current?.parentElement?.clientHeight ?? 0;
      const width = canvasRef.current?.parentElement?.clientWidth ?? 0;

      if (canvasRef.current) canvasRef.current.height = height * dpr;
      if (canvasRef.current) canvasRef.current.width = width * dpr;

      if (canvasRef.current) canvasRef.current.style.height = `${height}px`;
      if (canvasRef.current) canvasRef.current.style.width = `${width}px`;

      if (context) context.scale(dpr, dpr);
    };

    handleResize();

    let resizeTimer: ReturnType<typeof setTimeout>;

    const onResizeEnd = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(handleResize, 500); // this number must at least exceed the css animation time
    };

    if (canvasRef.current) {
      canvasRef.current.style.fill = "#FFF";
      window.addEventListener("resize", onResizeEnd);
    }

    return () => window.removeEventListener("resize", onResizeEnd);
  }, [canvasRef, setError]);

  return (
    <div className={cx(styles.canvasWrapper, className)}>
      <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};
