// import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

// Components
import App from "App";
import ErrorBoundary from "components/common/ErrorBoundary";

// Context
import { AppContextProviderComponent } from "context";

import "styles/globals.css";
import "styles/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ErrorBoundary>
    <AppContextProviderComponent>
      <App />
    </AppContextProviderComponent>
  </ErrorBoundary>
);
