import { useEffect, useState } from "react";

// Context
import { useAppContext } from "context";

// Components
import { Button } from "components/common/Button";
import { UserCard } from "components/common/UserCard";
import { Svg } from "components/common/Svg";

import styles from "./styles.module.css";

export const SummaryPageComponent = () => {
  const { formState, completeSession, frontDeskMode } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      throw Error("Failed to complete session in 15 seconds");
    }, 15_000);

    completeSession?.().then(() => {
      setLoading(false);
      clearTimeout(timeout);
    });

    return () => clearTimeout(timeout);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div>
        <h2>Completing Session...</h2>
      </div>
    );
  }

  return (
    <div className={styles.summaryPage}>
      {frontDeskMode ? (
        <div>
          <h2>The Customer has been added to HubSpot</h2>
          <p>A Fitness Consultant will follow up with them shortly.</p>
        </div>
      ) : (
        <div>
          <h2>Enjoy your visit, {formState.firstname}.</h2>
          <p>Our Fitness Consultant will be with you shortly.</p>
        </div>
      )}

      <UserCard />

      <Button
        data-cy="doneButton"
        className={styles.doneButton}
        version="primary"
        type="button"
        onClick={() => window.location.reload()}
      >
        <span>DONE</span>
        <Svg icon="check" />
      </Button>
    </div>
  );
};
