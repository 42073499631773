// Context
import { useAppContext } from "context";

// Components
import { Input } from "components/common/Input";
import { DateInput } from "components/common/Input/DateInput";

// Types
import type { FormComponentProps } from "services/types";

import styles from "./styles.module.css";

export const InputPageComponent = ({
  pageKey,
  isDate,
  labelText,
  helperText,
  inputPlaceholder,
  inputLabel,
}: FormComponentProps & { pageKey: string }) => {
  const { formState, formStateHandler, error, showError } = useAppContext();

  const formVal = formState[pageKey] || "";

  return (
    <div>
      <h2>{labelText}</h2>
      {isDate ? (
        <DateInput pageKey={pageKey} initialDate={formVal} />
      ) : (
        <Input
          id={pageKey}
          label={inputLabel}
          placeholder={inputPlaceholder}
          value={formVal}
          hasError={Boolean(showError && error)}
          onChange={({ target: { value } }) => formStateHandler(value)}
        />
      )}
      {showError && <p className={styles.error}>{error}</p>}
      <p className={styles.helperText}>{helperText}</p>
    </div>
  );
};
