import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Fuse from "fuse.js";

// Context
import { useAppContext } from "context";

// Services
import { makeHubspotRequest } from "services/hrm";

// Helpers
import { getDisplayTime } from "services/utils";

// Components
import { Button } from "components/common/Button";
import { Input } from "components/common/Input";
import { Option } from "components/common/Button/Option";

// Types
import { HubSpotSearchFilters, HubSpotSearchRequestData, HubSpotSearchResponse } from "services/types";

import styles from "./styles.module.css";

const CONTACT_SEARCH_PROPERTIES = [
  "firstname",
  "lastname",
  "email",
  "phone",
  "birth_date",
  "home_club",
  "abc_member_id",
  "abc_memberid",
];

const MEETING_SEARCH_PROPERTIES = [
  "hs_meeting_title",
  "hs_meeting_start_time",
  "hs_meeting_end_time",
  "home_club",
] as const;

const SEARCH_KEYS = ["contact.properties.firstname", "contact.properties.lastname"];

type HubspotProperties<T extends string> = { id: string; properties: Record<T, string> };

type ContactResult = HubspotProperties<(typeof CONTACT_SEARCH_PROPERTIES)[number]>;
type MeetingResult = HubspotProperties<(typeof MEETING_SEARCH_PROPERTIES)[number]>;
type MeetingWithAssociations = MeetingResult & {
  associations?: {
    contacts: {
      results: {
        /** Example: "590109351" */
        id: string;
        /** Example: "task_to_contact" */
        type: string;
      }[];
    };
  };
};

type TodaysMeetings = (MeetingWithAssociations & { contact: ContactResult })[];

const getPrivateName = (name: string) =>
  name
    .split("")
    .map((char, i) => (i < 3 ? char : "*"))
    .join("");

const getOptionLabel = ({ contact, properties }: MeetingResult & { contact: ContactResult }, privateName = false) =>
  `${getDisplayTime(properties.hs_meeting_start_time)}:  ${
    privateName ? getPrivateName(contact.properties.firstname) : contact.properties.firstname
  } ${privateName ? getPrivateName(contact.properties.lastname) : contact.properties.lastname}`;

const HubspotMeetingLink = ({ meeting }: { meeting: MeetingResult & { contact: ContactResult } }) => (
  <h4>
    <a
      target="_blank"
      rel="noreferrer"
      href={`https://app.hubspot.com/contacts/4595896/record/0-1/${meeting.contact.id}/view/1?engagement=${meeting.id}`}
    >
      {getOptionLabel(meeting)}
    </a>
  </h4>
);

export const AppointmentCheckinPageComponent = () => {
  const { clubId } = useAppContext();
  const { pageHandler, setSearchResultsOnFormState, setShouldSearch, debugMode } = useAppContext();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [contactMeeting, setContactMeeting] = useState<(MeetingResult & { contact: ContactResult }) | null>();
  const [todaysMeetings, setTodayMeetings] = useState<TodaysMeetings>();

  useEffect(() => {
    // If the name field changes, clear search results
    if (contactMeeting) setContactMeeting(undefined);
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  const todaySearchFilters: HubSpotSearchFilters = useMemo(
    () => [
      {
        propertyName: "hs_meeting_start_time",
        operator: "GT",
        value: new Date().setHours(0, 0),
      },
      {
        propertyName: "hs_meeting_end_time",
        operator: "LT",
        value: new Date().setHours(23, 59),
      },
    ],
    []
  );

  useEffect(() => {
    axios(`https://ash-marketing-connector-default-rtdb.firebaseio.com/hubSpotOwnersByLocation/${clubId}.json`).then(
      async (ownerIds) => {
        if (!ownerIds.data) throw new Error("No owners for this location");
        const filters = [...todaySearchFilters] as typeof todaySearchFilters;
        filters.push({ propertyName: "hubspot_owner_id", operator: "IN", values: Object.keys(ownerIds.data) });

        let limit = 100;
        let after: string | undefined;
        const allResults: MeetingResult[] = [];

        while (limit === 100) {
          const data: HubSpotSearchRequestData = {
            limit,
            filterGroups: [{ filters }],
            properties: CONTACT_SEARCH_PROPERTIES,
          };
          if (after) data.after = after;

          const { results, paging }: HubSpotSearchResponse<MeetingResult> = await makeHubspotRequest<{
            results?: MeetingResult[];
          }>({
            method: "POST",
            url: "/crm/v3/objects/meetings/search",
            data,
          });

          if (results) allResults.push(...results);
          if (paging?.next.after) after = paging?.next.after;

          limit = results?.length || 0;
        }

        // if (allResults.length <= 0) {
        //   push("errors", {
        //     error: `No results in waiver meetings search for club: ${clubId}`,
        //     errorInfo: {
        //       componentStack: JSON.stringify(filters),
        //     },
        //   });
        // } else {
        const clubMeetingsWithContacts = await Promise.all(
          allResults?.map(async ({ id }) => {
            const meetingWithContact = await makeHubspotRequest<MeetingWithAssociations>({
              method: "GET",
              url: `/crm/v3/objects/meetings/${id}?associations=contact&properties=${MEETING_SEARCH_PROPERTIES.join(
                ","
              )}`,
            });

            const associatedContactId = meetingWithContact?.associations?.contacts?.results?.[0]?.id;

            if (!associatedContactId) return null;

            const contact = await makeHubspotRequest<ContactResult>({
              method: "GET",
              url: `/crm/v3/objects/contacts/${associatedContactId}?properties=${CONTACT_SEARCH_PROPERTIES.join(",")}`,
            });

            return { ...meetingWithContact, contact };
          }) || []
        );

        setTodayMeetings(clubMeetingsWithContacts.filter(Boolean) as TodaysMeetings);
        // }

        setLoading(false);
      }
    );
  }, [clubId, todaySearchFilters]);

  const filteredSorted = useMemo(
    () =>
      todaysMeetings
        ?.filter((d) => d.properties.home_club !== clubId)
        .sort(
          (a, b) =>
            new Date(a.properties.hs_meeting_start_time).valueOf() -
            new Date(b.properties.hs_meeting_start_time).valueOf()
        ),
    [todaysMeetings, clubId]
  );

  const fuseInstance = useMemo(() => new Fuse(todaysMeetings || [], { keys: SEARCH_KEYS }), [todaysMeetings]);

  useEffect(() => {
    if (searching && !loading) {
      if (filteredSorted) {
        const found = fuseInstance.search(name || "").map(({ item }) => item);
        if (found[0]) {
          setContactMeeting(found[0]);
        } else {
          setContactMeeting(null);
        }
      } else {
        setContactMeeting(null);
      }
      setSearching(false);
    }
  }, [name, fuseInstance, searching, loading, filteredSorted]);

  const nextPage = useCallback(
    (meeting?: typeof contactMeeting) => () => {
      if (meeting?.contact?.id) {
        setShouldSearch(false);
        setSearchResultsOnFormState({ ...meeting.contact.properties, hubspotId: meeting.contact.id });
      }

      pageHandler(1);
    },
    [pageHandler, setSearchResultsOnFormState, setShouldSearch]
  );

  const button = useMemo(() => {
    let text = "Find my appointment";
    let onClick: () => void | Promise<void> = () => setSearching(true);
    if (searching && loading) {
      text = "Searching...";
    } else if (contactMeeting) {
      text = "This is not my appointment";
      onClick = () => setContactMeeting(null);
    } else if (contactMeeting === null) {
      text = "I don't see my appointment";
      onClick = nextPage();
    }

    return (
      <Button className={styles.noButton} disabled={loading} type="button" version="primaryGhost" onClick={onClick}>
        {text}
      </Button>
    );
  }, [searching, loading, contactMeeting, nextPage]);

  return (
    <div className={styles.searchPage}>
      <h2>Check in to your appointment</h2>
      <p>Please enter your name to check-in to your appointment</p>

      <Input
        id="name"
        placeholder="Enter your name"
        value={name}
        onChange={({ target: { value } }) => setName(value)}
      />

      {contactMeeting && <Option text={getOptionLabel(contactMeeting)} onClick={nextPage(contactMeeting)} />}

      {contactMeeting === null &&
        filteredSorted?.map((meeting) => (
          <Option key={meeting.id} text={getOptionLabel(meeting, true)} onClick={nextPage(meeting)} />
        ))}
      {button}

      {debugMode && (
        <div>
          <h2>HubSpot Links for Debugging</h2>
          <h4>Current Club ID: {clubId}</h4>
          {contactMeeting && (
            <div>
              <h4>Meeting for Found Contact</h4>
              <HubspotMeetingLink meeting={contactMeeting} />
            </div>
          )}

          {Boolean(todaysMeetings?.length) && <h4>Today&apos;s Meetings </h4>}
          {todaysMeetings?.map((meeting) => (
            <HubspotMeetingLink meeting={meeting} key={meeting.id} />
          ))}
        </div>
      )}
    </div>
  );
};
