import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";

// Services
import { initializeFirebase, onValue, onDisconnect, setValue, updateValue, getValue, pushKey } from "services/firebase";
import { getClub } from "services/api";

// Helpers
import { serverTimestamp } from "firebase/database";
import { useToggle } from "hooks/useToggle";

// Constants
import { FORM_PAGES, FRONT_DESK_MODE_PAGES, FormPage } from "services/form";

// Types
import type { User as FirebaseUser } from "firebase/auth";
import type { AbcClub } from "services/types";

type AppContext = {
  settings: { hubspotAppDataKey: string } | null | undefined;
  user: FirebaseUser | null | undefined;
  page: number;
  pageHandler: (direction: -2 | -1 | 1 | 2) => void;
  formState: Record<string, string | number | null | undefined>;
  formStateHandler: (value: string) => void;
  shouldSearch: boolean;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  setSearchResultsOnFormState: (result: Record<string, string | number | null>) => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  showError: boolean;
  sessionPath: string | null;
  completeSession?: () => Promise<void>;
  clubId: string;
  setClubId: Dispatch<SetStateAction<string>>;
  club: AbcClub | null | undefined;
  setClub: Dispatch<SetStateAction<AbcClub | null | undefined>>;
  frontDeskMode: boolean;
  toggleFrontDeskMode: () => void;
  pages: FormPage[];
  setPage: Dispatch<SetStateAction<number>>;
  debugMode: boolean;
};

const UserDataContext = createContext<AppContext>({
  settings: undefined,
  user: undefined,
  page: 0,
  pageHandler: () => {},
  formState: {},
  formStateHandler: () => {},
  shouldSearch: true,
  setShouldSearch: () => {},
  setSearchResultsOnFormState: () => {},
  error: "",
  setError: () => {},
  showError: false,
  sessionPath: "",
  clubId: "",
  setClubId: () => {},
  club: undefined,
  setClub: () => {},
  frontDeskMode: false,
  toggleFrontDeskMode: () => {},
  pages: [],
  setPage: () => {},
  debugMode: false,
});

export const useAppContext = () => useContext(UserDataContext);

export const AppContextProviderComponent = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<FirebaseUser | null>();
  const [settings, setSettings] = useState<AppContext["settings"]>();
  // TODO: Save club ID when it's entered and use onValue to watch for if it exists and then turn onValue off
  const [clubId, setClubId] = useState("");
  const [club, setClub] = useState<AbcClub | null>();

  const [shouldSearch, setShouldSearch] = useState(true);

  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const debugMode = useMemo(() => window.location.hash === "#debug", []);

  const [page, setPage] = useState(-1);
  const pageHandler = useCallback(
    (direction: -2 | -1 | 1 | 2) => {
      if (error && direction > 0) {
        setShowError(true);
      } else {
        setPage((p) => p + direction);
      }
      window.scrollTo(0, 0);
    },
    [error, setPage]
  );

  const sessionPath = useMemo(
    () => (user?.uid ? `kioskSession/${user.uid}/${pushKey(`kioskSession/${user.uid}/`)}` : null),
    [user?.uid]
  );

  const [frontDeskMode, toggleFrontDeskMode] = useToggle(false);
  useEffect(() => {
    if (user) updateValue(`${sessionPath}`, { frontDeskMode });
  }, [frontDeskMode, sessionPath, user]);

  const pages = frontDeskMode ? FRONT_DESK_MODE_PAGES : FORM_PAGES;

  const [formState, setFormState] = useState<Record<string, string | number | null>>({});
  const formStateHandler = useCallback(
    (value: string) => {
      const { pageKey, validator, processProps } = pages[page] || ({} as (typeof FORM_PAGES)[number]);

      const { val, allow, error: err } = validator?.(value) || { val: value, allow: true };

      setError(err || "");

      if (allow) {
        const updateObj = processProps?.(pageKey, val, formState) || { ...formState, [pageKey]: val };
        setFormState(updateObj);
        updateValue(`${sessionPath}`, updateObj);
        setShowError(false);

        if (!formState.hubspotId && ["phone", "email"].includes(pageKey)) setShouldSearch(true);
      }
    },
    [sessionPath, page, pages, formState]
  );

  const setSearchResultsOnFormState = useCallback(
    (result: Record<string, string | number | null>) => {
      const { hubspotId, firstname, lastname, birth_date, phone, email, abc_member_id, abc_memberid } = result;
      const obj = {} as typeof result;
      if (hubspotId) obj.hubspotId = hubspotId;
      if (firstname) obj.firstname = firstname;
      if (lastname) obj.lastname = lastname;
      if (birth_date) obj.birthday = birth_date;
      if (phone) obj.phone = phone;
      if (email) obj.email = email;
      if (abc_member_id || abc_memberid) obj.abcMemberId = abc_member_id || abc_memberid;

      setFormState((prev) => ({ ...prev, ...obj }));
      updateValue(`${sessionPath}`, obj);
    },
    [sessionPath]
  );

  const completeSession = useCallback(
    () => setValue(`${sessionPath}/triggerOnSessionComplete`, Date.now()),
    [sessionPath]
  );

  useEffect(() => {
    const { pageKey, validator } = pages[page] || {};
    const { error: err } = validator?.(formState[pageKey] || "") || { val: "" };
    setError(err || "");
  }, [setError, page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initializeFirebase(setUser);
    onValue("appSettings", setSettings);
  }, []);

  useEffect(() => {
    if (user) {
      getValue<string>(`uidLocationMap/${user.uid}`).then((id) => {
        if (id) {
          setClubId(id);
          if (settings?.hubspotAppDataKey) getClub(settings.hubspotAppDataKey, id).then(setClub);
        } else {
          setClub(null);
        }
      });
    }
  }, [user, settings?.hubspotAppDataKey]);

  useEffect(() => {
    const off = onDisconnect(`${sessionPath}/disconnectedAt`, serverTimestamp());

    return off;
  }, [sessionPath]);

  const ctx = useMemo(
    () => ({
      settings,
      user,
      page,
      pageHandler,
      error,
      setError,
      showError,
      formState,
      formStateHandler,
      shouldSearch,
      setShouldSearch,
      setSearchResultsOnFormState,
      sessionPath,
      completeSession,
      clubId,
      setClubId,
      club,
      setClub,
      frontDeskMode,
      toggleFrontDeskMode,
      pages,
      setPage,
      debugMode,
    }),
    [
      settings,
      user,
      page,
      pageHandler,
      error,
      setError,
      showError,
      formState,
      formStateHandler,
      shouldSearch,
      setShouldSearch,
      setSearchResultsOnFormState,
      sessionPath,
      completeSession,
      clubId,
      setClubId,
      club,
      setClub,
      frontDeskMode,
      toggleFrontDeskMode,
      pages,
      setPage,
      debugMode,
    ]
  );

  return <UserDataContext.Provider value={ctx}>{children}</UserDataContext.Provider>;
};
