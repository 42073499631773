import { useCallback, useEffect, useRef } from "react";

// Context
import { useAppContext } from "context";

// Services
import { firebaseStoragePutString } from "services/firebase";

// Components
import { CaptureSignature } from "components/common/CaptureSignature";
import { Button } from "components/common/Button";

// Constants
import { WAIVER } from "./constants";

// Types
import type { FormComponentProps } from "services/types";

import styles from "./styles.module.css";

const d = new Date();

export const WaiverPageComponent = ({ labelText }: FormComponentProps) => {
  const { sessionPath, formState, formStateHandler, user } = useAppContext();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const clearSignature = useCallback(() => {
    const context = canvasRef.current?.getContext("2d");
    if (canvasRef.current && context) context?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  }, []);

  useEffect(() => {
    const canvasEl = canvasRef.current;

    return () => {
      if (canvasEl) {
        const newCanvas = canvasEl.cloneNode(true) as typeof canvasEl;
        const ctx = newCanvas.getContext("2d");

        if (ctx) {
          ctx.fillStyle = "#FFF";
          ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
          ctx.drawImage(canvasEl, 0, 0);

          const dataUrl = newCanvas.toDataURL("image/jpeg");
          if (dataUrl && sessionPath && user) {
            firebaseStoragePutString(`${sessionPath}/signature_image.jpg`, dataUrl, "data_url").then((imageUrl) =>
              formStateHandler(imageUrl)
            );
          }
        }
      }
    };
  }, [user, sessionPath, formStateHandler]);

  return (
    <div className={styles.waiverPage}>
      <h2>{labelText}</h2>

      <p className={styles.waiver}>{WAIVER}</p>

      <hr />

      <Button className={styles.clearButton} version="ghost" type="button" onClick={clearSignature}>
        CLEAR
      </Button>

      <CaptureSignature canvasRef={canvasRef} className={styles.signatureCustomClass} />

      <div className={styles.bottom}>
        <div>
          <p className={styles.footnote}>Signature - use your finger to sign in the box.</p>

          <div className={styles.signatureDetails}>
            <span>
              {formState.firstname} {formState.lastname}
            </span>
            <span>
              {String(d.getDate()).padStart(2, "0")}{" "}
              {Intl.DateTimeFormat("en-US", { month: "long", year: "numeric" }).format(d)}
            </span>
          </div>
        </div>
        <Button
          className={styles.toTopButton}
          version="primary"
          type="button"
          onClick={() => window.scrollTo({ top: 0 })}
        >
          BACK TO TOP
        </Button>
      </div>
    </div>
  );
};
