// HubSpot Request Manager
// There is an EC2 instance running this app: https://github.com/sprintfwd/hubspot-request-manager
// It processes requests and saves the responses
// This function sends the request and receives the responses.

// Services
import { onValue, set, push, getDatabase, ref, child } from "firebase/database";

// Types
import type { AxiosRequestConfig } from "axios";

export const makeHubspotRequest = async <T extends object>(req: AxiosRequestConfig) => {
  const dbRef = ref(getDatabase(), "hubspotRequestManager");

  const reqKey = push(dbRef).key!;
  await set(child(dbRef, `/requests/${reqKey}`), { createdAt: Date.now(), ...req });

  return new Promise<T>((resolve) => {
    const off = onValue(child(dbRef, `/responses/${reqKey}`), (snap) => {
      const resp = snap.val();
      if (resp !== null) {
        const { error, ...rest } = resp as { error: object };
        if (error) {
          throw Error(JSON.stringify(error));
        }
        resolve((rest as { data: T }).data);
        off();
      }
    });
  });
};
