import { SVGProps } from "react";

export const SVG = {
  "arrow-left": (props: SVGProps<SVGSVGElement>, title: string | undefined) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {title && <title>{title}</title>}
      <path
        d="M23.625 15.25H24.875V12.75H23.625V15.25ZM4.375 12.75C3.68464 12.75 3.125 13.3096 3.125 14C3.125 14.6904 3.68464 15.25 4.375 15.25V12.75ZM23.625 12.75H4.375V15.25H23.625V12.75Z"
        fill="#00000C"
      />
      <path d="M12.25 6.125L4.375 14L12.25 21.875" stroke="#00000C" strokeWidth="2.5" strokeLinecap="square" />
    </svg>
  ),
  "arrow-right": (props: SVGProps<SVGSVGElement>, title: string | undefined) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "rotate(180deg)" }}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {title && <title>{title}</title>}
      <path
        d="M23.6245 15.25H24.8745V12.75H23.6245V15.25ZM4.37451 12.75C3.68416 12.75 3.12451 13.3096 3.12451 14C3.12451 14.6904 3.68416 15.25 4.37451 15.25V12.75ZM23.6245 12.75H4.37451V15.25H23.6245V12.75Z"
        fill="white"
      />
      <path d="M12.2495 6.125L4.37451 14L12.2495 21.875" stroke="white" strokeWidth="2.5" strokeLinecap="square" />
    </svg>
  ),
  check: (props: SVGProps<SVGSVGElement>, title: string | undefined) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {title && <title>{title}</title>}
      <g clipPath="url(#clip0_20_2552)">
        <path d="M23.625 7.875L11.375 20.125L5.25 14" stroke="white" strokeWidth="3" strokeLinecap="square" />
      </g>
      <defs>
        <clipPath id="clip0_20_2552">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  plus: (props: SVGProps<SVGSVGElement>, title: string | undefined) => (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {title && <title>{title}</title>}
      <g clipPath="url(#clip0_19_1869)">
        <path d="M4.375 14H23.625" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M14 4.375V23.625" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_19_1869">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};
