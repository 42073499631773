import { memo, useRef, useEffect, useState, DetailedHTMLProps, ButtonHTMLAttributes, PropsWithChildren } from "react";
import cx from "classnames";

// Helpers
import { blackOrWhiteFromBackground } from "utils/format";
import { getCSSStringProperty } from "styles/utils";

import styles from "./styles.module.css";

type DefaultButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type ButtonProps = Omit<DefaultButtonProps, "type" | "children"> & { type: "submit" | "reset" | "button" };

type TProps = PropsWithChildren<
  ButtonProps & {
    version: keyof typeof VERSION_MAP;
  }
>;

const VERSION_MAP = {
  primaryGhost: "transparent",
  secondaryGhost: "transparent",
  blackGhost: "transparent",
  whiteGhost: "transparent",
  ghost: "transparent",
  primary: "var(--primaryColor)",
  secondary: "var(--secondaryColor)",
  tertiary: "var(--tertiaryColor)",
};

export const Button = memo(function Button({ children, version, className, ...buttonProps }: TProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [textColor, setTextColor] = useState("black");

  useEffect(() => {
    const backgroundColor = getCSSStringProperty("background-color" as "backgroundColor", buttonRef.current);
    const alpha = Number(backgroundColor.match(/(\d)/g)?.[3] || 1);

    // If the background is transparent, set the text color to black
    if (backgroundColor === "transparent" || alpha <= 0.4) setTextColor("var(--darkBlack)");
    // Otherwise set it based on the calculation
    else setTextColor(blackOrWhiteFromBackground(backgroundColor, "rgb"));
  }, [version]);

  return (
    <button
      ref={buttonRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      // eslint-disable-next-line react/button-has-type
      type={buttonProps.type}
      className={cx(styles.button, styles[version], className)}
      style={{ "--customColor": textColor, backgroundColor: VERSION_MAP[version] }}
    >
      {children}
    </button>
  );
});
