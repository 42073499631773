import { useCallback } from "react";

// Context
import { useAppContext } from "context";

// Components
import { Option } from "components/common/Button/Option";

// Types
import type { FormComponentProps } from "services/types";

import styles from "./styles.module.css";
import { HERE_FOR_APPOINTMENT } from "services/form";

export const OptionsPageComponent = ({ options, labelText }: FormComponentProps) => {
  const { formStateHandler, pageHandler, page, frontDeskMode } = useAppContext();

  const setState = useCallback(
    (val: string) => () => {
      formStateHandler(val);
      // Skip the appointment page if they didn't select HERE_FOR_APPOINTMENT in the non-frontdesk mode
      pageHandler(!frontDeskMode && page === 0 && val !== HERE_FOR_APPOINTMENT ? 2 : 1);
    },
    [formStateHandler, pageHandler, page, frontDeskMode]
  );

  return (
    <div className={styles.options}>
      <h2>{labelText}</h2>
      {options?.map((text) => (
        <Option key={text} text={text} onClick={setState(text)} />
      ))}
    </div>
  );
};
