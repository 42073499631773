import { useEffect, useState } from "react";

// Context
import { useAppContext } from "context";

// Services
import { searchHubspot } from "services/api";

// Components
import { Svg } from "components/common/Svg";
import { UserCard } from "components/common/UserCard";
import { Button } from "components/common/Button";

import styles from "./styles.module.css";

const SEARCH_ATTEMPT_PROPS = ["email", "phone"] as const;
const RETURN_PROPS = [
  ...SEARCH_ATTEMPT_PROPS,
  "firstname",
  "lastname",
  "birth_date",
  "abc_member_id",
  "abc_memberid",
] as const;

type Results = Exclude<(typeof RETURN_PROPS)[number], "abc_member_id" | "abc_memberid"> | "abcMemberId" | "hubspotId";

export const SearchPageComponent = () => {
  const { settings, formState, pageHandler, shouldSearch, setShouldSearch } = useAppContext();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<Record<Results, string | number | null>[]>([]);

  useEffect(() => {
    if (shouldSearch && settings?.hubspotAppDataKey) {
      searchHubspot(settings.hubspotAppDataKey, {
        properties: formState,
        findInHubspot: {
          searchAttemptProperties: SEARCH_ATTEMPT_PROPS,
          returnProperties: RETURN_PROPS,
        },
      })
        .then((r) => {
          if (r?.length) {
            setResults(
              r.map(({ id, properties: { abc_member_id, abc_memberid, ...properties } }) => ({
                hubspotId: id,
                abcMemberId: abc_member_id || abc_memberid || null,
                ...properties,
              }))
            );
            setLoading(false);
          } else {
            pageHandler(1);
            setShouldSearch(false);
          }
        })
        .catch((e) => {
          console.error(e);
          pageHandler(1);
          setShouldSearch(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (shouldSearch && loading) {
    return (
      <div className={styles.loading}>
        <h1>Searching...</h1>
      </div>
    );
  }

  return (
    <div className={styles.searchPage}>
      <div>
        <h2>We found {results.length > 1 ? "multiple people" : "someone"} with this information.</h2>
        <p>{results.length > 1 ? "Are you one of them?" : "Is this you?"}</p>
      </div>

      {results.map((r) => (
        <UserCard key={r.hubspotId} option={r} />
      ))}

      <Button className={styles.noButton} type="button" version="secondary" onClick={() => pageHandler(1)}>
        <span>I&apos;m not one of them</span>
        <Svg icon="arrow-right" />
      </Button>
    </div>
  );
};
