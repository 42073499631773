import * as database from "firebase/database";
import {
  ref as storageRef,
  getStorage,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadString,
  StringFormat,
} from "firebase/storage";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, signInAnonymously, User as FirebaseUser } from "firebase/auth";

/*
██╗███╗   ██╗██╗████████╗██╗ █████╗ ██╗     ██╗███████╗███████╗
██║████╗  ██║██║╚══██╔══╝██║██╔══██╗██║     ██║╚══███╔╝██╔════╝
██║██╔██╗ ██║██║   ██║   ██║███████║██║     ██║  ███╔╝ █████╗
██║██║╚██╗██║██║   ██║   ██║██╔══██║██║     ██║ ███╔╝  ██╔══╝
██║██║ ╚████║██║   ██║   ██║██║  ██║███████╗██║███████╗███████╗
╚═╝╚═╝  ╚═══╝╚═╝   ╚═╝   ╚═╝╚═╝  ╚═╝╚══════╝╚═╝╚══════╝╚══════╝
*/
export const initializeFirebase = (cb: (userData: FirebaseUser | null) => void) => {
  if (getApps().length === 0) {
    initializeApp({
      apiKey: "AIzaSyBAe7aP3LeUgQVzhcPvYwE3U26AMZwSSK0",
      authDomain: "eos-guest-waiver.firebaseapp.com",
      projectId: "eos-guest-waiver",
      storageBucket: "eos-guest-waiver.appspot.com",
      messagingSenderId: "118191107924",
      appId: "1:118191107924:web:0ead5276d25e97bd2722c1",
    });
    signInAnonymously(getAuth());
  }

  return getAuth().onAuthStateChanged(cb);
};

const getRef = (path: string, allowRootQuery: boolean = false) => {
  if (!path || (!allowRootQuery && path === "/")) throw new Error("We don't like root queries");

  return database.ref(database.getDatabase(), path);
};

export const onValue = <T>(path: string, cb: (val: T) => void) =>
  database.onValue(getRef(path), (snap) => cb(snap.val()));

export const getValue = <T>(path: string) => database.get(getRef(path)).then((snap) => snap.val() as T | null);

export const setValue = <T>(path: string, data: T) => database.set(getRef(path), data);

export const updateValue = <T extends object>(path: string, data: T) => database.update(getRef(path), data);

export const push = <T>(path: string, data: T) => database.push(getRef(path), data);

export const pushKey = (path: string) => database.push(getRef(path)).key!;

export const onDisconnect = (path: string, data: unknown) => {
  const onDis = database.onDisconnect(getRef(path));
  onDis.set(data);

  return () => {
    onDis.cancel();
  };
};

/*
███████╗████████╗ ██████╗ ██████╗  █████╗  ██████╗ ███████╗
██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔══██╗██╔════╝ ██╔════╝
███████╗   ██║   ██║   ██║██████╔╝███████║██║  ███╗█████╗
╚════██║   ██║   ██║   ██║██╔══██╗██╔══██║██║   ██║██╔══╝
███████║   ██║   ╚██████╔╝██║  ██║██║  ██║╚██████╔╝███████╗
╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝
*/
/** DO NOT USE outside of strongly typed services */
export const firebaseStorageRef = (path: string) => storageRef(getStorage(), path);

export const firebaseStoragePut = (path: string, file: Blob | Uint8Array | ArrayBuffer) =>
  uploadBytes(firebaseStorageRef(path), file).then(({ ref }) => getDownloadURL(ref));

export const firebaseStoragePutString = (path: string, data: string, format: StringFormat) =>
  uploadString(firebaseStorageRef(path), data, format).then(({ ref }) => getDownloadURL(ref));

export const firebaseStorageDelete = (path: string) => deleteObject(firebaseStorageRef(path));
