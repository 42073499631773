import { useEffect, useMemo, useRef, useState } from "react";

// Context
import { useAppContext } from "context";

// Components
import { Input } from "components/common/Input";

// Local
import styles from "./styles.module.css";

const LAST_CENTENNIAL = new Date().getFullYear() - 100;
const ADULT_AGE = new Date().getFullYear() - 18;

type TProps = {
  pageKey: string;
  initialDate: string | number;
};

export const DateInput = ({ pageKey, initialDate }: TProps) => {
  const { formStateHandler, setError } = useAppContext();
  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [y, m, d] = useMemo(
    () => (initialDate && typeof initialDate === "string" ? initialDate.split("-") : ["", "", ""]),
    [initialDate]
  );

  const [year, setYear] = useState(y);
  const [month, setMonth] = useState(m);
  const [day, setDay] = useState(d);

  useEffect(() => {
    if (year.length === 4 && Number(month) && Number(day)) {
      formStateHandler(`${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`);
    } else {
      setError("Invalid birthday");
    }
  }, [year, month, day, formStateHandler, setError]);

  // TODO: If we are guaranteed Safari on an iPad we might be able to use a native date picker
  return (
    <div className={styles.dateInput}>
      <Input
        inputRef={yearRef}
        id={`${pageKey}-year`}
        placeholder="YYYY"
        value={year}
        onChange={({ target: { value } }) => {
          const num = Number(value);
          const isNum = !Number.isNaN(Number(num));
          const valid = num >= LAST_CENTENNIAL && num <= ADULT_AGE;

          if (isNum || !value) {
            setYear(value);
            if (valid) monthRef.current?.focus();
          }
        }}
      />
      <Input
        inputRef={monthRef}
        id={`${pageKey}-month`}
        placeholder="MM"
        value={month}
        onChange={({ target: { value } }) => {
          const num = Number(value);
          const valid =
            !Number.isNaN(Number(num)) &&
            ((value.length === 1 && num >= 0) || (value.length > 1 && num >= 1)) &&
            num <= 12;

          if (valid || !value) {
            setMonth(value);
            if (value.length === 2) dayRef.current?.focus();
          }
        }}
      />
      <Input
        inputRef={dayRef}
        id={`${pageKey}-day`}
        placeholder="DD"
        value={day}
        onChange={({ target: { value } }) => {
          const num = Number(value);

          const maxDays = new Date(new Date().setFullYear(Number(year), Number(month) + 2, 0)).getDate();
          const valid =
            !Number.isNaN(Number(num)) &&
            ((value.length === 1 && num >= 0) || (value.length > 1 && num >= 1)) &&
            num <= maxDays;

          if (valid || !value) setDay(value);
        }}
      />
    </div>
  );
};
