import React, { useCallback } from "react";
import cx from "classnames";

// Context
import { useAppContext } from "context";

// Components
import { Button } from "components/common/Button";
import { Svg } from "components/common/Svg";

import styles from "./styles.module.css";

type TProps = {
  option?: Record<string, string | number | null>;
};

export const UserCard = ({ option }: TProps) => {
  const { formState, setSearchResultsOnFormState, pageHandler, pages, setPage } = useAppContext();
  const isOptionVersion = Boolean(option);

  const { firstname, lastname, phone, email } = option || formState;

  const handleSelectOption = useCallback(() => {
    if (option) {
      setSearchResultsOnFormState(option);
      if (formState.here_for_an_appointment === "true") {
        const signaturePageIndex = pages.findIndex(({ pageKey }) => pageKey === "signature_image");
        setPage(signaturePageIndex);
      } else {
        pageHandler(1);
      }
    }
  }, [setSearchResultsOnFormState, pageHandler, setPage, option, formState.here_for_an_appointment, pages]);

  return (
    <div className={styles.userCard}>
      <div className={styles.left}>
        <div className={cx(styles.name, { [styles.row]: isOptionVersion })}>
          <div>{firstname}</div>
          <div>{lastname}</div>
        </div>

        {!isOptionVersion && (
          <div>
            <div>{phone}</div>
            <div>{email}</div>
          </div>
        )}
      </div>

      <div className={styles.right}>
        {isOptionVersion && (
          <Button data-cy="userCardButton" type="button" version="primary" onClick={handleSelectOption}>
            <Svg icon="arrow-right" />
          </Button>
        )}
      </div>
    </div>
  );
};
