type Listener = {
  event: string;
  cb: EventListener;
  type: "body" | "canvas";
};

export const removeEventListeners = (canvas: HTMLCanvasElement, listeners: Listener[]) =>
  listeners.forEach(({ event, cb, type }) => {
    (type === "canvas" ? canvas : document.body).removeEventListener(event, cb, false);
  });

export const addEventListeners = (canvas: HTMLCanvasElement, listeners: Listener[]) =>
  listeners.forEach(({ event, cb, type }) => {
    (type === "canvas" ? canvas : document.body).addEventListener(event, cb, false);
  });

const isTouchTypeguard = (e: Event): e is TouchEvent => Boolean((e as TouchEvent).changedTouches);

export const getCoords = (canvas: HTMLCanvasElement, e: Event) => {
  const event = isTouchTypeguard(e) ? e.changedTouches[0] : (e as MouseEvent);
  if (!event) return { x: 0, y: 0 };

  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;

  return { x, y };
};
