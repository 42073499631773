import { memo, SVGProps } from "react";

import { SVG } from "./constants";

export type SvgIcons = keyof typeof SVG;

export type TProps = SVGProps<SVGSVGElement> & {
  icon: SvgIcons;
  title?: string;
};

// For some reason eslint prop validation conflicts with memo being called here inline
const SvgComp = ({ icon, title, ...props }: TProps) => SVG[icon](props, title);

export const Svg = memo(SvgComp);
