// Components
import { SummaryPageComponent } from "components/pages/SummaryPageComponent";
import { InputPageComponent } from "components/pages/InputPageComponent";
import { OptionsPageComponent } from "components/pages/OptionsPageComponent";
// import { UploadPageComponent } from "components/pages/UploadPageComponent";
import { WaiverPageComponent } from "components/pages/WaiverPageComponent";
import { SearchPageComponent } from "components/pages/SearchPageComponent";

// Types
import type { FormComponentProps } from "services/types";

// Helpers
import {
  phoneNumberValidator,
  emailValidator,
  nameValidator,
  getDateString,
  emailOptionalValidator,
} from "services/utils";
import { AppointmentCheckinPageComponent } from "components/pages/AppointmentCheckinPageComponent";

const NO_SAVE = "DONT_SAVE";

export const HERE_FOR_APPOINTMENT = "I'm here for an Appointment";

export type FormPage = {
  pageKey: string;
  showNextButton: boolean;
  props: FormComponentProps;
  processProps?: (
    pageKey: string,
    val: string | number,
    prev: Record<string, string | number | null>
  ) => Record<string, string | number | null>;
  validator?: (val: string | number) => { val: string | number; allow: boolean; error: string | undefined };
  component: (props: FormComponentProps & { pageKey: string }) => JSX.Element;
};

export const FORM_PAGES: FormPage[] = [
  {
    pageKey: "visitor_status",
    showNextButton: false,
    props: {
      labelText: "What would you like to do?",
      options: ["New Guest", "New VIP Guest", "Paid Visit", HERE_FOR_APPOINTMENT],
    },
    processProps: (pageKey, val, prev) => {
      const newPrev = { ...prev, [pageKey]: val };

      if (val === HERE_FOR_APPOINTMENT) {
        newPrev.here_for_an_appointment = "true";
        newPrev.guest_appointment_time = Date.now();
      } else {
        delete newPrev.here_for_an_appointment;
        delete newPrev.guest_appointment_time;
      }

      return newPrev;
    },
    component: OptionsPageComponent,
  },
  {
    pageKey: "hubspotId",
    showNextButton: false,
    props: {},
    component: AppointmentCheckinPageComponent,
  },
  {
    pageKey: "referral_source",
    showNextButton: false,
    props: {
      labelText: "How did you hear about us?",
      options: [
        "A Current Member",
        "Billboard",
        "My Employer",
        "Direct Mail",
        "Door Hanger",
        "Drive By",
        "Guest Pass",
        "Previous Member",
        "Radio",
        "Television",
        "Website - EOS Fitness",
        "Group Exercise",
        "Social Media",
        "Online Ad",
        "Sporting Event",
        "Other",
      ],
    },
    component: OptionsPageComponent,
  },
  {
    pageKey: "phone",
    showNextButton: true,
    props: {
      labelText: "What is your mobile phone number?",
      helperText: "Your mobile number is your ID. We will never use it to spam or share it with others.",
      inputPlaceholder: "212-555-2222",
    },
    validator: phoneNumberValidator,
    component: InputPageComponent,
  },
  {
    pageKey: "email",
    showNextButton: true,
    props: {
      labelText: "What is your email?",
      inputLabel: "Email",
    },
    validator: emailValidator,
    component: InputPageComponent,
  },
  {
    pageKey: `SEARCH_${NO_SAVE}`,
    showNextButton: false,
    props: {},
    validator: undefined,
    component: SearchPageComponent,
  },
  {
    pageKey: "firstname",
    showNextButton: true,
    props: {
      labelText: "What is your first name?",
      inputLabel: "First Name",
    },
    validator: nameValidator,
    component: InputPageComponent,
  },
  {
    pageKey: "lastname",
    showNextButton: true,
    props: {
      labelText: "What is your last name?",
      inputLabel: "Last Name",
    },
    validator: nameValidator,
    component: InputPageComponent,
  },
  {
    pageKey: "birthday",
    showNextButton: true,
    props: {
      labelText: "When is your birthday?",
      isDate: true,
    },
    component: InputPageComponent,
  },
  // {
  //   pageKey: "profile_photo",
  //   showNextButton: false,
  //   props: {
  //     labelText: "Add a profile photo to help us get to know you quicker.",
  //   },
  //   component: UploadPageComponent,
  // },
  {
    pageKey: "signature_image",
    showNextButton: true,
    props: {
      labelText: "Agreement",
    },
    processProps: (pageKey, val, prev) => ({
      ...prev,
      [pageKey]: val,
      signature_date: getDateString(),
    }),
    component: WaiverPageComponent,
  },
  {
    pageKey: `SUMMARY_${NO_SAVE}`,
    showNextButton: false,
    props: {},
    component: SummaryPageComponent,
  },
];

export const FRONT_DESK_MODE_PAGES: FormPage[] = [
  {
    pageKey: "referral_source",
    showNextButton: false,
    props: {
      labelText: "Referral Source",
      options: [
        "Telephone Inquiry",
        "A Current Member",
        "Billboard",
        "My Employer",
        "Direct Mail",
        "Door Hanger",
        "Drive By",
        "Guest Pass",
        "Previous Member",
        "Radio",
        "Television",
        "Website - EOS Fitness",
        "Group Exercise",
        "Social Media",
        "Online Ad",
        "Sporting Event",
        "Other",
      ],
    },
    component: OptionsPageComponent,
  },
  {
    pageKey: "phone",
    showNextButton: true,
    props: {
      labelText: "What is their phone number:",
      inputPlaceholder: "212-555-2222",
    },
    validator: phoneNumberValidator,
    component: InputPageComponent,
  },
  {
    pageKey: "email",
    showNextButton: true,
    props: {
      labelText: "What is their email (optional):",
      inputLabel: "Email",
    },
    validator: emailOptionalValidator,
    component: InputPageComponent,
  },
  {
    pageKey: `SEARCH_${NO_SAVE}`,
    showNextButton: false,
    props: {},
    validator: undefined,
    component: SearchPageComponent,
  },
  {
    pageKey: "firstname",
    showNextButton: true,
    props: {
      labelText: "What is their first name?",
      inputLabel: "First Name",
    },
    validator: nameValidator,
    component: InputPageComponent,
  },
  {
    pageKey: "lastname",
    showNextButton: true,
    props: {
      labelText: "What is their last name?",
      inputLabel: "Last Name",
    },
    validator: nameValidator,
    component: InputPageComponent,
  },
  {
    pageKey: `SUMMARY_${NO_SAVE}`,
    showNextButton: false,
    props: {},
    component: SummaryPageComponent,
  },
];
