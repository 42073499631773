import axios from "axios";

import type { AbcClub } from "./types";

const axiosInstance = axios.create({
  baseURL: "https://marketing-connector.vercel.app/api",
});

export const getClub = (dataKey: string, clubId: string) =>
  axiosInstance
    .get(`/integrations/${dataKey}/club-check/${clubId}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then(({ data }) => data?.club as AbcClub | undefined);

export const searchHubspot = <T extends string>(
  dataKey: string,
  body: {
    properties: Record<string, string | number | null | undefined>;
    findInHubspot: {
      searchAttemptProperties: readonly string[];
      returnProperties: readonly T[];
    };
  }
) =>
  axiosInstance
    .post<{ id: string; properties: Record<T, string> }[] | null>(`/integrations/${dataKey}/search-hubspot`, body)
    .then(({ data }) => data);
