// Components
import { Button } from "components/common/Button";
import { Svg } from "components/common/Svg";

import styles from "./styles.module.css";

type TProps = {
  text: string;
  onClick: VoidFunction;
};

export const Option = ({ text, onClick }: TProps) => (
  <Button version="primary" type="button" onClick={onClick}>
    <div className={styles.option}>
      <span>{text}</span>
      <Svg icon="arrow-right" />
    </div>
  </Button>
);
