import { useCallback, Dispatch, SetStateAction } from "react";

// Context
import { useAppContext } from "context";

// Services
import { getClub } from "services/api";
import { setValue } from "services/firebase";

// Components
import { Button } from "components/common/Button";
import { Input } from "components/common/Input";
import { Svg } from "components/common/Svg";

import styles from "./App.module.css";

type TProps = {
  setShowAdminFacing: Dispatch<SetStateAction<boolean | undefined>>;
};

function AppAdmin({ setShowAdminFacing }: TProps) {
  const { user, settings, setClub, club, setClubId, clubId } = useAppContext();

  const handleGetClub = useCallback(() => {
    if (settings?.hubspotAppDataKey) {
      getClub(settings.hubspotAppDataKey, clubId).then(async (c) => {
        if (c) {
          setClub(c);
          if (user) await setValue(`uidLocationMap/${user.uid}`, clubId);
        }
      });
    }
  }, [clubId, setClub, user, settings?.hubspotAppDataKey]);

  return (
    <div className={styles.adminFacing}>
      <div>
        <h2>
          {club ? (
            <>
              Signed in as: {club.name}
              <br />
              {club.address1}
            </>
          ) : (
            "Enter your activation code to begin"
          )}
        </h2>
        <p>
          {club
            ? "Enter an activation code to sign in with another acccount."
            : "To find the activation code, ask your marketing manager."}
        </p>
      </div>

      <Input
        id="clubId"
        value={clubId}
        onChange={({ target: { value } }) => !Number.isNaN(Number(value)) && setClubId(value)}
      />

      <div className={styles.buttons}>
        <Button id="signInAdmin" disabled={!clubId.length} type="button" version="primary" onClick={handleGetClub}>
          <span>SIGN IN</span>
          <Svg icon="arrow-right" />
        </Button>

        {club && (
          <Button type="button" version="tertiary" onClick={() => setShowAdminFacing(false)}>
            CANCEL
          </Button>
        )}
      </div>
    </div>
  );
}

export default AppAdmin;
