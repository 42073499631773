// Context
import { useAppContext } from "context";

// Components
import { Button } from "components/common/Button";
import { Svg } from "components/common/Svg";

import styles from "./styles.module.css";
import { useCallback } from "react";

type TProps = {
  showNextButton: boolean;
};

export const Header = ({ showNextButton }: TProps) => {
  const { page, frontDeskMode, formState, pageHandler, shouldSearch, pages } = useAppContext();
  const { pageKey: prevPage } = pages[page - 1] || {};
  const { pageKey: nextPage } = pages[page + 1] || {};

  const handlePrev = useCallback(() => {
    const shouldSkipPrev =
      (prevPage === "email" && !frontDeskMode) ||
      (!shouldSearch && prevPage === "SEARCH_DONT_SAVE") ||
      (prevPage === "appointment_checkin" && formState.here_for_an_appointment !== "true");

    pageHandler(shouldSkipPrev ? -2 : -1);
  }, [formState.here_for_an_appointment, pageHandler, prevPage, shouldSearch, frontDeskMode]);

  const handleNext = useCallback(() => {
    const shouldSkipNext = nextPage === "SEARCH_DONT_SAVE" && !shouldSearch;

    pageHandler(shouldSkipNext ? 2 : 1);
  }, [nextPage, pageHandler, shouldSearch]);

  return (
    <header className={styles.header}>
      <div>
        <Button className={styles.backButton} type="button" version="ghost" onClick={handlePrev}>
          <Svg icon="arrow-left" />
        </Button>
      </div>

      <div>
        {showNextButton && (
          <Button data-cy="nextButton" type="button" version="primary" onClick={handleNext}>
            <Svg icon="arrow-right" />
          </Button>
        )}
      </div>
    </header>
  );
};
